export const pageData = {
  header: {
    title: 'Enhance your Fitness App with leading services’ data: Google Fit, Strava, and more',
    text: 'We offer integrations with the top fitness services via an API, including Google Fit and Apple Health Kit. The only thing standing between you and a powerful app is a quick consultation. Let’s chat!',
    btnText: 'Get free consultation',
  },
  features: {
    title: 'Why should you integrate?',
    subtitle:
      "Perhaps your app is already great – but by connecting to a leading fitness service via an API,it can be phenomenal. Here's why you shouldn't skip out on one:",
    items: [
      {
        title: 'Get new customers',
        subtitle:
          'Apple Health and Google Fit advertise apps on their homepages, thus putting eyes where you want them: on your amazing product. ',
      },
      {
        title: 'Expand your reach',
        subtitle:
          'By integrating with a fitness service, you can access a whole new market – wearables. Accessibility for smart watches will broaden your reach significantly.',
      },
      {
        title: 'Leverage new data',
        subtitle:
          "When you connect your app to a fitness service like Google Fit or Strava via API, you'll get access to large amounts of valuable data – without any additional development costs.",
      },
      {
        title: 'Increase app engagement',
        subtitle:
          'Your app can connect to hundreds of other leading health and wellness apps via API, thus boosting your clients’ engagement and providing them with a better user experience.  ',
      },
    ],
  },
  workProcess: {
    title: 'How we work',
    tabs: [
      {
        title: 'Free Consultation',
      },
      {
        title: 'Discovery Phase',
      },
      {
        title: 'Integration',
      },
      {
        title: 'Testing',
      },
      {
        title: 'Launch',
      },
      {
        title: 'Support',
      },
    ],
    tabsData: [
      {
        title: "We'll go over:",
        list: [
          'Preliminary information on your app and business',
          'Which service is ideal for your application',
          'Who your point-of-contact will be',
          'How long the integration will take',
        ],
      },
      {
        title: "We'll conduct:",
        list: [
          'Research related to your app and business',
          'An analysis of your competitors and target audience',
          "An analysis of whether your app meets the API's requirements",
        ],
      },
      {
        title: 'During integration:',
        list: [
          'Software engineers and the project manager will get to work',
          "We'll complete the development cycle",
        ],
      },
      {
        title: "We want to make sure that the integration went smoothly! We'll:",
        list: [
          'Test interactions',
          "Ensure code compliance with the API's requirements",
          'Check performance, security, and functionality',
        ],
      },
      {
        title: 'Almost done! We will:',
        list: ['Upload the app to the app store(s)', 'Ensure the approval'],
      },
      {
        title: "It doesn't end there; after the app launches, we will:",
        list: ['Provide ongoing support', 'Conduct maintenance'],
      },
    ],
  },
  faq: [
    {
      question: 'Is this a custom solution for my app?',
      answer:
        'Yes. Our team can provide any kind of solution - from analyzing requirements to launching and supporting an application.',
    },
    {
      question: 'Should my app already exist, or could it be a prototype?',
      answer:
        'Your app can be at any stage of development. Our team has extensive experience in fitness software development.',
    },
    {
      question: 'Will there be additional fees for using services like GoogleFit, Strava, etc.?',
      answer:
        'Yes, there will be. But the fees are worth it; you will get an additional advertising channel and greater customer loyalty.',
    },
    {
      question: "Can my app collect data from clients' fitness trackers on its own?",
      answer: 'Brocoders can sync your app to almost any list of devices.',
    },
  ],
};
