import { graphql, useStaticQuery } from 'gatsby';
import { IData } from './components/slide/index';

export const useCarouselData = (): IData[] => {
  const {
    googleFit: {
      childImageSharp: { fluid: googleFit },
    },
    garminHealth: {
      childImageSharp: { fluid: garminHealth },
    },
    strava: {
      childImageSharp: { fluid: strava },
    },
    auraCloud: {
      childImageSharp: { fluid: auraCloud },
    },
    amazonHalo: {
      childImageSharp: { fluid: amazonHalo },
    },
    appleHealthKit: {
      childImageSharp: { fluid: appleHealthKit },
    },
  } = useStaticQuery(graphql`
    query {
      googleFit: file(relativePath: { eq: "integration/google-fit.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 454) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      garminHealth: file(relativePath: { eq: "integration/garmin-health.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 454) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      strava: file(relativePath: { eq: "integration/strava.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 454) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      auraCloud: file(relativePath: { eq: "integration/aura-cloud.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 454) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      amazonHalo: file(relativePath: { eq: "integration/amazon-halo.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 454) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      appleHealthKit: file(relativePath: { eq: "integration/apple-health-kit.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 454) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return [
    {
      img: googleFit,
      title: 'Google FIT',
      text: 'Google Fit is simple to use, clean, and compatible with hundreds of other apps through a single integration. With insights from Google Fit, you can build powerful features that enable end-users to exercise better, eat healthier, and sleep more restfully.',
    },
    {
      img: garminHealth,
      title: 'Garmin Health',
      text: 'Garmin Health lets you leverage comprehensive metrics that are suitable for patient monitoring, corporate wellness, general fitness, and other applications. The Garmin Health ecosystem is known for its incredible accuracy, which powers sleep metrics, heart rate trackers, stress measurers, and more.',
    },
    {
      img: strava,
      title: 'Strava',
      text: 'Strava is designed by athletes for athletes. Upon completing a race, bike ride, or workout, users can upload performance stats and share them with friends and followers. Strava is all about connection: it fosters healthy competition and social interaction between millions of runners and cyclists.',
    },
    {
      img: auraCloud,
      title: 'Oura Cloud',
      text: 'The Oura Ring offers powerful body-tracking technology from a very stylish-looking ring – and Oura Cloud makes it simple to access the data it generates. By connecting to it via API, you can provide users with three types of daily summaries: sleep, activity, and readiness.',
    },
    {
      img: amazonHalo,
      title: 'Amazon Halo',
      text: 'Amazon Halo is a wearable band that offers innovative tools and personalized insights; each one is carefully crafted to help the user improve their overall health and wellness. It goes beyond diet and nutrition; with Amazon Halo, you can even analyze the tone of your voice to be more aware of how you sound to others.',
    },
    {
      img: appleHealthKit,
      title: 'Apple Health Kit',
      text: "Apple Health Kit offers the most data features out of any other leading fitness service; it can help you build a truly powerful application. It goes above and beyond by offering features like menstrual tracking and environmental sound monitoring. What's more, it offers superb privacy and security measures.",
    },
  ];
};
