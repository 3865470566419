import React, { useRef, FC } from 'react';
// styles
import styles from './booking.module.css';
//components
import Person from '../person';
import Booking from './container';
// hooks
import usePrimaryContact from 'hooks/usePrimaryContact';
import useSpecialContact from 'hooks/useSpecialContact';
import { useHasBeenVisible } from 'hooks/useVisibility.js';
import clsx from 'clsx';

export interface IFeedback {
  scrollRef?: React.RefObject<HTMLDivElement>;
  ctaRef?: React.RefObject<HTMLDivElement>;
  defaultIndex?: number;
  spesialContact?: boolean;
  title?: string | React.ReactNode;
  subtitle?: string;
}

const Feedback: FC<IFeedback> = ({
  scrollRef,
  ctaRef,
  defaultIndex,
  spesialContact = false,
  title,
  subtitle,
}) => {
  const contact = spesialContact ? useSpecialContact() : usePrimaryContact();
  const bookingLoadRef = useRef<HTMLDivElement>(null);
  const isVisible = useHasBeenVisible(bookingLoadRef);

  return (
    <section className={styles.section} ref={bookingLoadRef}>
      <div className={clsx('inner', styles.cta)} ref={ctaRef}>
        <h2 className="title arrow">
          {title ? (
            title
          ) : (
            <>
              {'Schedule a call or send \r\n us a'}&nbsp;{'message'}
            </>
          )}
        </h2>
        <p className="subtitle">
          {subtitle
            ? subtitle
            : 'We are thrilled about the opportunity to provide software development services for your business'}
        </p>
        <div className={styles.column} ref={scrollRef}>
          <Person
            title={contact.position}
            name={contact.full_name}
            ava={contact.avatar.childImageSharp.fluid}
            customClass={styles.person}
          />
          <p className={styles.text}>{contact.subtitle}</p>
        </div>
      </div>
      <div className={styles.container}>{isVisible && <Booking defaultIndex={defaultIndex} />}</div>
    </section>
  );
};

export default React.memo(Feedback);
