import React from 'react';
// components
import loadable from '@loadable/component';
import Slide from './components/slide';
// constants
import { useCarouselData } from './constants';

const SwiperSlider = loadable(() => import('components/swiper-slider'));

const IntegrationCarousel = () => {
  const carouselData = useCarouselData();

  return (
    <SwiperSlider>
      {carouselData.map((item, idx) => (
        <Slide key={idx} data={item} />
      ))}
    </SwiperSlider>
  );
};

export default IntegrationCarousel;
