import React, { FC } from 'react';
// utils
import clsx from 'clsx';
// styles
import styles from './features.module.css';

interface IProps {
  data: {
    title: string;
    subtitle: string;
    items: {
      title: string;
      subtitle?: string;
      list?: string[];
    }[];
  };
}

const Features: FC<IProps> = ({ data: { title, subtitle, items } }) => (
  <section className={clsx(styles.section, 'inner')}>
    <h2 className="title">{title}</h2>
    <p className={clsx(styles.subtitle, 'subtitle')}>{subtitle}</p>
    <div className={styles.blocksContainer}>
      {items.map(({ title, subtitle, list }, idx) => (
        <div key={`${title}-${idx}`} className={styles.blockContainer}>
          <h3 className={styles.blockTitle}>{title}</h3>
          {subtitle && <p className={styles.blockSubtitle}>{subtitle}</p>}
          {list && (
            <ul className={styles.list}>
              {list.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  </section>
);

export default Features;
