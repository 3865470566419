import React, { FC } from 'react';
// gatsby
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
// styles
import styles from './slide.module.css';

export interface IData {
  title: string;
  text: string;
  img: FluidObject;
}

interface IProps {
  data: IData;
}

const Slide: FC<IProps> = ({ data: { title, text, img } }) => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "bg/bg-integration-slide.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <li className={styles.container}>
      <div className={styles.bgAfter} />
      <BackgroundImage className={styles.bg} fluid={data.bg.childImageSharp.fluid} />
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.text}>{text}</p>
        </div>
        <div className={styles.imgContainer}>
          <GatsbyImage fluid={img} className={styles.img} />
        </div>
      </div>
    </li>
  );
};

export default Slide;
