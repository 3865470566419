import React, { FC } from 'react';
// utils/helpers
import clsx from 'clsx';
// styles
import styles from './work-process.module.css';
// components
import Tabs from 'components/tabs';

interface IData {
  title: string;
  subtitle?: string;
  tabs: { title: string }[];
  tabsData: { title: string; list: string[] }[];
}

interface IProps {
  data: IData;
}

const WorkProcess: FC<IProps> = ({ data: { title, subtitle, tabs, tabsData } }) => (
  <section className={clsx('section', styles.section)}>
    <div className="inner">
      <h2 className="title">{title}</h2>
      {subtitle && <p className="subtitle">{subtitle}</p>}
      <Tabs list={tabs} btnClassName="tabs__btn_30" isArrow>
        {tabsData.map(({ title, list }, idx) => (
          <li key={`${title}-${idx}`} className="tabs__item">
            <div>
              <h3>{title}</h3>
              <ul className={styles.bullets}>
                {list.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </Tabs>
    </div>
  </section>
);

export default WorkProcess;
