import React, { useRef } from 'react';
import loadable from '@loadable/component';
// components
import Layout from 'components/layout';
import GetConsultation from 'components/free-consultation';
import Footer from 'components/layout/footer';
import FAQ from 'components/faq';
import Booking from 'components/feedback';
// sections
import Header from 'components/landing-pages/sections/header';
import Features from 'components/landing-pages/sections/features';
// import IntegrationForm from 'components/landing-pages/sections/integration-form';
import WorkProcess from 'components/landing-pages/sections/work-process';
import IntegrationCarousel from 'components/landing-pages/sections/integration-carousel';
// constants
import { pageData } from './constants';
// meta
import { meta } from 'metaData';
import { Ifit } from 'components/carousel';

const FitnessAppApiIntegrations = () => {
  const bookingRef = useRef<HTMLDivElement>(null);

  const IntegrationForm = loadable(
    () => import('components/landing-pages/sections/integration-form')
  );

  const handleClickCTA = () => {
    if (bookingRef.current !== null) {
      bookingRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <Layout
      metaData={{ main: meta.fitnessAppApiIntegrations }}
      navBtnTextColor="var(--black-color)"
    >
      <Header onClickCTA={handleClickCTA} data={pageData.header} />
      <main style={{ overflow: 'hidden' }}>
        <Features data={pageData.features} />
        {/* <SwiperSlider> */}
        <Ifit />
        {/* </SwiperSlider> */}
        <GetConsultation
          title="Want to enrich your fitness app with accurate data?"
          linkText="Contact us"
          onClick={handleClickCTA}
        />
        <IntegrationCarousel />
        <IntegrationForm />
        <WorkProcess data={pageData.workProcess} />
        <GetConsultation
          title="Ready to make your fitness app more effective?"
          linkText="Let's talk"
          onClick={handleClickCTA}
        />
        <div className="inner">
          <FAQ data={{ list: pageData.faq }} />
        </div>
        <Booking scrollRef={bookingRef} />
      </main>
      <Footer />
    </Layout>
  );
};

export default FitnessAppApiIntegrations;
