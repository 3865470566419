import { graphql, useStaticQuery } from 'gatsby';

export default function useSpecialContact() {
  const {
    strapiBaseSetting: { special_contact },
  } = useStaticQuery(graphql`
    query {
      strapiBaseSetting {
        special_contact {
          full_name
          position
          calendly_url
          subtitle
          avatar {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return special_contact;
}
