import React, { FC } from 'react';
// gatsby
import { useStaticQuery, graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import BackgroundImage, { IFluidObject } from 'gatsby-background-image';
// utils
import clsx from 'clsx';
// styles
import styles from './header.module.css';

const Routes = require('../../../../components/routes').Routes;

interface IProps {
  onClickCTA: () => void;
  data: {
    title: string;
    text: string;
    btnText: string;
    bgImg?: IFluidObject;
    img?: IFluidObject;
    imgClassName?: string;
    fitnessAppCaclBtn?: boolean;
  };
  hideImg?: boolean;
}

const Header: FC<IProps> = ({
  onClickCTA,
  data: { title, text, btnText, bgImg, img, imgClassName, fitnessAppCaclBtn },
  hideImg,
}) => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "fitness-app-api-header-bg.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img: file(relativePath: { eq: "fitness-app-api-header-img.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 396) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      Tag="header"
      className={styles.bg}
      fluid={bgImg ?? data.bg.childImageSharp.fluid}
      preserveStackingContext
    >
      <div className={styles.textContainer}>
        <div>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.text}>{text}</p>
          {fitnessAppCaclBtn ? (
            <>
              <Link
                className={clsx('btn btn_60 btn__tr', styles.btn)}
                to={Routes.FITNESS_APP_CALCULATOR}
              >
                App cost calculator
              </Link>
              <button
                className={clsx(styles.btn, 'btn btn_60 btn__white')}
                type="button"
                onClick={onClickCTA}
              >
                {btnText}
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={onClickCTA}
              className={clsx(styles.btn, 'btn btn_60 btn__white')}
            >
              {btnText}
            </button>
          )}
        </div>
        {!hideImg && (
          <GatsbyImage
            className={clsx(styles.img, imgClassName)}
            fluid={img ?? data.img.childImageSharp.fluid}
          />
        )}
      </div>
      <div className={styles.wave} />
    </BackgroundImage>
  );
};

export default Header;
